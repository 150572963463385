<template>
  <div class="heading mt-8">
    <img class="logo" v-if="$route.name != 'start' && store.booth && logo" :src="logo" alt="Logo">
    <img class="logo" v-if="$route.name === 'start' && store.booth && startLogo" :src="startLogo" alt="Logo">
    <h1 v-if="heading">{{ heading }}</h1>
    <h2 v-if="subheading">{{ subheading }}</h2>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '~/stores/store'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n() 
const store = useStore()
const route = useRoute()

const heading = computed<string | undefined>(() => {
  switch (route.name) {
    case 'survey': return store.event?.booth_survey?.survey.title
    case 'mode': return t('mode.heading')
    case 'template': return t('template_select.heading')
    case 'camera': return t('camera.heading')
    case 'background': return t('bg_selector.heading')
    case 'preview': return t('preview.heading')
    case 'sharing': return t('data_capture.heading')
    case 'thank_you': return t('thank_you.heading')
    case 'stickers': return t('stickers.heading')
    default: return ''
  }
})
const subheading = computed<string | undefined>(() => {
  switch (route.name) {
    case 'survey': return store.event?.booth_survey?.survey.description
    case 'mode': return t('mode.subheading')
    case 'template': return t('template_select.subheading')
    case 'camera': return t('camera.subheading')
    case 'background': return t('bg_selector.subheading')
    case 'preview': return t('preview.subheading')
    case 'sharing': return t('data_capture.description')
    case 'thank_you': return t('thank_you.subheading')
    case 'stickers': return t('stickers.subheading')
    default: return ''
  }
})
const logo = computed<string | undefined>(() => {
  if (store.booth?.logo) {
    return URL.createObjectURL(store.booth?.logo)
  } else if (!store.booth?.delete_logo) {
    return store.booth?.logo_url
  }
  return undefined
})
const startLogo = computed<string | undefined>(() =>  {
  if (store.booth?.start_logo) {
    return URL.createObjectURL(store.booth?.start_logo)
  } else if (!store.booth?.delete_start_logo) {
    return store.booth?.start_logo_url
  }
  return undefined
})
</script>
