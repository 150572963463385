import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { useStore } from './stores/store'
import App from './App.vue'
import { $wait } from './services/wait'
import router from './router/router'
import './main.css'
import NextBtn from './components/buttons/Next.vue'
import { createI18n } from 'vue-i18n'
import { boothTranslations } from './translations'
import { isPreview, modifySpecialSymbols} from '~/utils/utils'

void (async function main () {
  const app = createApp(App)
  const pinia = createPinia()
  
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({ app, dsn: import.meta.env.VITE_SENTRY_DSN })
  }

  app.component('NextBtn', NextBtn)
    .use($wait)
    .use(router)
    .use(pinia)

  const store = useStore()
  
  if(isPreview()) {
    store.preview = true
  } else {
    await store.load()
  }
  
  const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'default',
    messages: {default: boothTranslations, ...{ en: modifySpecialSymbols(store.booth?.translations?.en) } } 
  })

  app.use(i18n)
  app.provide('i18n', i18n)

  app.mount('#booth')
  await router.push(store.initialLocation)
}())
