<template>
  <div class="mx-auto w-full md:w-1/2 lg:w-1/3 xl:w-1/4 space-y-6">
    <div class="flex justify-center mt-4 flex-col space-y-4">
      <NextBtn @click="reset" :text="$t('thank_you.restart_text')" icon="camera" />
      <NextBtn @click="openMicrositePage()" :text="$t('thank_you.view_text')" icon="gallery" />
      <Btn v-if="store.booth?.thank_you_custom_btn" :text="store.booth?.thank_you_custom_btn_text" :link="store.booth?.thank_you_custom_btn_url" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '~/stores/store'
import { micrositeId } from '~/utils/utils'
import { show } from '../gateways/photos'
import Btn from '../components/buttons/Btn.vue'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const reset = () => {
  store.reset()
  router.push({ name: 'mode' })
}
const openMicrositePage = async () => {
  const { data: { microsite_photo_url: url } } = await show(store.photoId)
  store.photoId = 0
  window.localStorage.removeItem(`photo_${micrositeId}`)
  if (window.top) {
    window.top.location.href = url
  } else {
    window.location.href = url
  }
}
</script>