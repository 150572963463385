<template>
  <div>
    <div class="mb-5 input-label">
      {{label}}
    </div>
    <div class="flex flex-wrap gap-2">
      <CheckboxInput
        v-for="option in options"
        :key="option.id"
        :label="option.value"
        :model-value="selected.indexOf(option.value) >= 0"
        @update:modelValue="toggle(option.value)"
      />
      <div class="flex-1" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { some, filter } from 'fp-ts/Array'
import { not } from 'fp-ts/Predicate'
import CheckboxInput from './Checkbox.vue'

export default defineComponent({
  components: { CheckboxInput },
  props: {
    options: {
      required: true,
      type: Array as PropType<SurveyQuestionOption[]>
    },
    modelValue: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    }
  },
  computed: {
    selected: {
      get (): string[] {
        if (!this.modelValue) return []
        return this.modelValue.split(', ')
      },
      set (value: string[]) {
        this.$emit('update:modelValue', value.join(', '))
      }
    }
  },
  methods: {
    toggle (value: string) {
      const match = (v: string) => (v === value)
      if (some(match)(this.selected)) {
        this.selected = filter(not(match))(this.selected)
      } else {
        this.selected = [...this.selected, value]
      }
    }
  }
})
</script>
