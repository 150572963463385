<template>
  <div
    v-if="visible"
    class="loading"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="spinner" fill="none" viewBox="0 0 48 48">
      <path d="M47.089 30.543l-4.087-6.54.002-.003-8.756-14.004h9.215C46.306 13.94 48 18.767 48 24c0 2.27-.321 4.463-.911 6.543zM22.591.054C23.059.026 23.526 0 24 0c7.091 0 13.443 3.093 17.837 7.983H17.636L22.591.054zm-7.58 7.929h-.023v.037L6.311 21.897l-4.462-7.138C4.943 7.352 11.645 1.839 19.757.389l-4.746 7.594zM4.996 24l8.754 14H4.534C1.692 34.057 0 29.231 0 24c0-2.265.335-4.448.922-6.525l4.076 6.522-.002.003zm25.379 16l-4.955 7.928c-.473.028-.941.072-1.42.072-7.099 0-13.459-3.101-17.853-8h24.228zm11.314-13.897l4.449 7.117c-3.094 7.403-9.777 12.93-17.889 14.382L33 40l8.689-13.897z"/>
    </svg>
    <div class="text">
      <template v-if="loading">
        {{ $t('global.loading_text') }}
      </template>
      <template v-if="saving">
        {{ $t('global.saving_text') }}
      </template>
      <template v-if="processing">
        {{ $t('global.processing_text') }}
      </template>
      <template v-if="uploading">
        {{ $t('global.uploading_text') }}
      </template>
      <template v-if="capturing">
        {{ $t('global.capturing_text') }}
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { Wait } from '~/services/wait';

const wait = inject('$wait') as Wait

const visible = computed<boolean>(() => loading.value || saving.value || processing.value || uploading.value || capturing.value )
const loading = computed<boolean>(() => wait.is('loading.*'))
const saving = computed<boolean>(() => wait.is('saving.*') )
const processing = computed<boolean>(() => wait.is('processing.*') )
const uploading = computed<boolean>(() => wait.is('uploading.*') )
const capturing = computed<boolean>(() => wait.is('capturing.*') )
</script>

<style scoped lang="postcss">
.loading {
  --loading-height: 3.6rem;
  --loading-margin: 0.2rem;
  height: var(--loading-height);
  background-color: theme('colors.white');
  border-radius: calc(var(--loading-height) / 2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: theme('colors.gray.600');
  @apply fixed flex z-50 shadow-lg;
  .text {
    margin: 0 1.4rem;
    line-height: var(--loading-height);
  }
  .spinner {
    height: calc(var(--loading-height) - (var(--loading-margin) * 2));
    width: calc(var(--loading-height) - (var(--loading-margin) * 2));
    margin: var(--loading-margin);
    animation: spin 4s linear infinite;
  }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}
</style>
