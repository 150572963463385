import api from './api'
import { AxiosResponse } from 'axios'
import { micrositeId } from '~/utils/utils'

type ResponseParams = {
  response: SurveyResponse;
}

export function create (params: ResponseParams): Promise<AxiosResponse<{ id: number }>> {
  return api.post(`${micrositeId}/responses`, params)
}
