<template>
  <div>
    <div class="current-preview">
      <img
        class="object-contain max-h-full"
        ref="frame"
        :src="currentFrame"
      >
    </div>
    <div
      class="flex justify-center mt-4 space-x-4"
    >
      <button
        @click="redo"
        type="button"
        class="px-5"
      >
        {{ $t('global.retake_button') }}
      </button>
      <NextBtn @click="save" :text="$t('preview.button_text')" icon="share" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '~/stores/store'
import { micrositeId } from '~/utils/utils'
import { create } from '../gateways/photos'
import { dataURLtoFile } from '~/utils/utils'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const currentFrameIdx = ref(0)
const goingUp = ref(true)
const player = ref<null | number>(null)

const currentFrame = computed<string>(() => {
  const currentFrame = store.framesForUpload[currentFrameIdx.value]
  if (!currentFrame) throw 'No frame available'
  return currentFrame
})

onMounted(() => {
  player.value = setInterval(
    () => step(),
    (store.template as Template).speed * 100)
})
onBeforeUnmount(() => {
  if (typeof player.value === 'number') clearInterval(player.value)
})

const step = async () => {
  if (!store.template) return
  if (store.template?.reverse) {
    if (goingUp.value && currentFrameIdx.value + 1 < store.template.frames.length) {
      currentFrameIdx.value += 1
    } else if (goingUp.value) {
      goingUp.value = false
    } else if (currentFrameIdx.value > 0) {
      currentFrameIdx.value -= 1
    } else {
      goingUp.value = true
    }
  } else {
    currentFrameIdx.value = (currentFrameIdx.value + 1) % store.template.frames.length
  }
}
const save = async () => {
  const store = useStore()
  const photos = store.framesForUpload.map(dataURLtoFile)
  if (!store.template) return

  const photo: PhotoParams = {
    photo_template_id: store.template.id,
    props_used: store.propsUsed,
    photos
  }

  if (store.responseId) {
    photo.response_id = store.responseId
  }

  const { data: { id, microsite_photo_url: micrositePhotoUrl } } = await create(photo)

  store.photoId = id
  if (store.event?.booth_data_capture && store.event?.booth_data_capture.enabled) {
    router.push({ name: 'sharing' })
  } else if (store.booth?.thank_you_enabled) {
    router.push({ name: 'thank_you' })
  } else {
    window.localStorage.removeItem(`photo_${micrositeId}`)
    store.photoId = 0
    if (window.top) {
      window.top.location.href = micrositePhotoUrl
    } else {
      window.location.href = micrositePhotoUrl
    }
  }
}
const redo = () => {
  store.redo()
  router.push({ name: 'mode' })
}
</script>

<style lang="postcss" scoped>
.frame {
  max-height: 80vh;
  max-width: 100%;
  @apply mx-auto relative z-20;
}
.photos {
  @apply absolute inset-0 z-10;
  > div {
    transform-origin: 0 0;
  }
}
</style>