const boothTranslations = {
  global: {
    retake_button: 'Retake',
    next_button: 'Next',
    terms_link_text: 'Terms Of Service',
    privacy_link_text: 'Privacy Policy',
    loading_text: 'LOADING...',
    saving_text: 'SAVING...',
    processing_text: 'PROCESSING...',
    uploading_text: 'UPLOADING...',
    capturing_text: 'CAPTURING...',
  },
  social_sharing: {
    title: 'Super Awesome Event!',
    description: 'Take a photo in our amazing photo booth!',
  },
  disclaimer: {
    accept: 'Accept',
    disclaimer_text: '',
  },
  start: {
    button_text: "Let's get started!",
    heading: 'Welcome to the Booth!',
    subheading: 'Get ready to take photos, videos or gifs with the photo booth!',
  },
  mode: {
    heading: 'Select Your Mode:',
    subheading: 'Choose below!',
  },
  template_select: {
    heading: 'Select your template!',
    subheading: 'Select the one you like most:',
  },
  mode_photo: {
    name: 'Still',
    description: 'Captures a single photo',
  },
  mode_gif: {
    name: 'GIF',
    description: 'A set of photos will be taken and converted into GIF',
  },
  mode_burst: {
    name: 'Burst',
    description: 'A burst/boomerang series will be taken. Ready for action?',
  },
  camera: {
    heading: 'Strike a pose!',
    subheading: 'Get in position and press start!',
    button_text: 'Start',
  },
  preview: {
    heading: 'YOU LOOK AWESOME!!!',
    subheading: 'Press "Save" to save the image and continue!',
    button_text: 'Save',
  },
  thank_you: {
    heading: 'All Done!',
    subheading: 'What do you want to do next?',
    view_text: 'View my photo',
    restart_text: 'Take another photo',
    custom_btn_text: 'Visit PicPic Social',
  },
  coming_soon: {
    heading: 'Coming Soon!',
    subheading: 'The photo booth is coming. Check back later.',
  },
  booth_over: {
    heading: 'The Event is Over!',
    subheading: 'The photo booth is now over. Thanks for stopping by!',
  },
  bg_selector: {
    heading: 'Select your background!',
    subheading: 'Select the one you like most:',
  },
  stickers: {
    heading: 'Add Some Stickers',
    subheading: 'Select the one you like most:',
  },
  data_capture: {
    heading: '',
    description: '',
    name: 'What is your name?',
    phone: 'What is your phone?',
    email: 'What is your e-mail?',
  },
};
  
export {
  boothTranslations,
};
  