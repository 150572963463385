<template>
  <div class="px-8 space-y-6">
    <div
      class="mode md:w-1/2 lg:w-1/3 xl:w-1/4"
      :class="{ active: mode === 'photo' }"
      :style="{ borderColor: store.booth?.border_color }"
      @click="mode = 'photo'"
      v-if="store.modes['photo'].length"
    >
      <div class="mode-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32" style="width: 2.06rem; height: 2rem">
          <path d="M.499 0v32h32V0H.5zm30.125 30.125H2.374V1.875h28.25v28.25z"/>
          <path d="M28.351 4.25H4.646v19.062H28.35V4.25zm-9.316 8.607a1.692 1.692 0 113.385 0 1.692 1.692 0 01-3.385 0zm2.918 3.566a4.526 4.526 0 014.526 4.526v.489h-9.042v-.49a4.526 4.526 0 014.525-4.525h-.01zM6.52 6.125h19.958v10.298a6.402 6.402 0 00-2.758-1.628 3.566 3.566 0 10-5.271.803 6.446 6.446 0 00-1.95 2.014 6.447 6.447 0 00-1.949-2.015 3.567 3.567 0 10-5.27-.803c-1.042.3-1.991.86-2.758 1.628V6.125h-.002zm5.749 8.423a1.692 1.692 0 11-.003-3.384 1.692 1.692 0 01.004 3.384h-.001zm-5.75 6.394a4.526 4.526 0 119.041 0v.49h-9.04v-.49zM12.271 25.75h8.456v1.875h-8.456V25.75z"/>
        </svg>
      </div>
      <div
        class="vl"
        :style="{ borderLeftColor: store.booth?.border_color }"
      />
      <div class="text">
        <h3>{{ $t('mode_photo.name') }}</h3>
        <p v-if="$t('mode_photo.description')">
          {{ $t('mode_photo.description') }}
        </p>
      </div>
    </div>
    <div
    v-if="store.modes['gif'].length"
      class="mode md:w-1/2 lg:w-1/3 xl:w-1/4"
      :class="{ active: mode === 'gif' }"
      :style="{ borderColor: store.booth?.border_color }"
      @click="mode = 'gif'"
    >
      <div class="mode-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 31" style="width: 2.19rem; height: 1.94rem">
          <path d="M17.549 10.723a3.534 3.534 0 100 7.067 3.534 3.534 0 000-7.067zm0 5.385a1.851 1.851 0 11.092-3.7 1.851 1.851 0 01-.092 3.7z"/>
          <path d="M31.65 2.644L8.67.03A3.156 3.156 0 006.19.747a3.198 3.198 0 00-1.22 2.187l-.421 3.452H3.243A3.381 3.381 0 00.002 9.881V27.09a3.198 3.198 0 003.107 3.28h23.234a3.453 3.453 0 003.535-3.282v-.673a4.208 4.208 0 001.598-.673 3.493 3.493 0 001.22-2.272l1.936-17.083a3.409 3.409 0 00-2.982-3.743zM28.197 27.09a1.771 1.771 0 01-1.854 1.596h-23.1a1.515 1.515 0 01-1.558-1.47v-3.24l6.522-4.797a2.02 2.02 0 012.61.127l4.585 4.039a3.955 3.955 0 002.483.92 3.83 3.83 0 002.02-.546l8.285-4.797v8.163l.007.005zm0-10.14l-9.173 5.343a2.146 2.146 0 01-2.482-.21l-4.629-4.082a3.746 3.746 0 00-4.67-.168l-5.554 4.039V9.88a1.699 1.699 0 011.557-1.809h23.097a1.936 1.936 0 011.854 1.81v7.069zm4.757-10.788v.017L30.97 23.26a1.43 1.43 0 01-.547 1.137c-.168.168-.546.252-.546.337V9.88a3.618 3.618 0 00-3.535-3.493H6.23l.379-3.282c.082-.425.304-.81.63-1.094.37-.256.818-.375 1.266-.337l22.932 2.647A1.682 1.682 0 0132.95 6.16l.004.002z"/>
        </svg>
      </div>
      <div
        class="vl"
        :style="{ borderLeftColor: store.booth?.border_color }"
      />
      <div class="text">
        <h3>{{ $t('mode_gif.name') }}</h3>
        <p v-if="$t('mode_gif.description')">
          {{ $t('mode_gif.description') }}
        </p>
      </div>
    </div>
    <div
      v-if="store.modes['burst'].length"
      class="mode md:w-1/2 lg:w-1/3 xl:w-1/4"
      :class="{ active: mode === 'burst' }"
      :style="{ borderColor: store.booth?.border_color }"
      @click="mode = 'burst'"
    >
      <div class="mode-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 59 23" style="width: 3.69rem; height: 1.44rem">
          <path d="M19.094 22.482h20.868a4.329 4.329 0 004.324-4.324V4.324A4.329 4.329 0 0039.962 0H19.094a4.328 4.328 0 00-4.324 4.324v13.835a4.329 4.329 0 004.324 4.323zM16.501 4.324a2.597 2.597 0 012.593-2.593h20.868a2.598 2.598 0 012.594 2.594V18.16a2.598 2.598 0 01-2.594 2.594H19.094a2.598 2.598 0 01-2.593-2.594V4.324z"/>
          <path d="M25.205 5.448v11.754L35.61 11.23 25.205 5.448zm1.73 2.939l5.16 2.867-5.16 2.962V8.387z"/>
          <path stroke-width=".6" d="M11.245 21.171a.508.508 0 00-.326-.64A9.45 9.45 0 015.86 16.31a8.826 8.826 0 01-.773-7.225 9.011 9.011 0 012.677-3.996c.8-.694 1.703-1.257 2.679-1.668L9.63 5.667a.509.509 0 00.956.346l1.125-3.11a.508.508 0 00-.426-.836L8.109 1.15a.509.509 0 10-.282.977l1.833.529c-.927.434-1.79.994-2.563 1.664a10.03 10.03 0 00-2.976 4.448 9.83 9.83 0 00.86 8.045 10.493 10.493 0 005.626 4.681.508.508 0 00.638-.323zM47.656 1.19a.508.508 0 00.359.622 9.452 9.452 0 015.273 3.953 8.826 8.826 0 011.149 7.176 9.013 9.013 0 01-2.465 4.13 10.027 10.027 0 01-2.588 1.806l.694-2.287a.51.51 0 00-.973-.296l-.96 3.165a.508.508 0 00.468.813l3.22.75a.51.51 0 10.24-.987l-.01-.003-1.857-.433a11.094 11.094 0 002.472-1.796 10.03 10.03 0 002.74-4.598 9.83 9.83 0 00-1.278-7.99 10.494 10.494 0 00-5.863-4.38.508.508 0 00-.621.355z"/>
        </svg>
      </div>
      <div
        class="vl"
        :style="{ borderLeftColor: store.booth?.border_color }"
      />
      <div class="text">
        <h3>{{ $t('mode_burst.name') }}</h3>
        <p v-if="$t('mode_burst.description')">
          {{ $t('mode_burst.description') }}
        </p>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <NextBtn :disabled="!mode" @click="setMode" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useStore } from '~/stores/store'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const mode: Ref<null | BoothMode> = ref(null)

const setMode = () => {
  if (mode.value) {
    store.mode = mode.value
    store.preselectTemplate()
    router.push({ name: 'template' })
  }
}

</script>

<style lang="postcss">
.mode {
  @apply border rounded border-gray-200 cursor-pointer flex items-center w-full mx-auto p-px;
  &.active {
    @apply border-2 p-0;
  }
  .mode-icon {
    min-width: 5rem;
    min-height: 5rem;
    @apply h-20 w-20 flex items-center justify-center;
    svg {
      max-width: 80%;
      width: 80%;
      max-height: 40%;
      height: 40%;
    }
  }
  .vl {
    margin-right: 1.8rem;
    border-left-width: 1px;
    @apply self-stretch my-4 opacity-50;
  }
  .text {
    color: v-bind('store.booth?.style?.bodyFont?.color');
    font-family: v-bind('store.booth?.style?.bodyFont?.fontFamily?.id || "inherit"');
    @apply py-4 pr-4;
    p {
      @apply text-sm opacity-50 mt-1 leading-4;
    }
    h3 {
      font-size: 1.375rem;
      line-height: 1.625rem;
    }
  }
}
</style>