<template>
  <label>
    <div class="mb-5 input-label">
      {{label}}
    </div>
    <input
      :type="type"
      :model-value="modelValue"
      :placeholder="placeholder"
      @input="input"
      :required="required"
    >
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    type: {
      default: 'text',
      type: String as PropType<'text' | 'email' | 'tel'>
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    input(event: Event) {
      this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
    }
  }
})
</script>

<style scoped lang="postcss">
label {
  @apply block;
  input {
    @apply block mt-6 w-full py-3 px-5 rounded border;
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
}
</style>
