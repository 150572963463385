<template>
  <div class="radio">
    <div class="mb-5 input-label">
      {{label}}
    </div>
    <div class="options grid md:flex">
      <div
        v-for="option in options"
        :key="option.id"
        @click="$emit('update:modelValue', option.value)"
        :class="{ option: true, active: option.value === modelValue }"
      >
        {{option.value}}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    options: {
      required: true,
      type: Array as PropType<SurveyQuestionOption[]>
    },
    modelValue: {
      required: true,
      type: String as PropType<null | string>
    },
    label: {
      required: true,
      type: String
    }
  }
})
</script>

<style scoped lang="postcss">
.radio .options {
  .option {
    @apply flex-1 py-2 text-center border-l border-t border-b cursor-pointer;
    border-color: rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    &:first-child {
      @apply rounded-l;
    }
    &:last-child {
      @apply rounded-r border-r;
    }
    &.active {
      box-shadow: 0 0 0 1px white;
      background: rgba(0, 0, 0, 0.2);
      @apply border-white border-r border z-10;
    }
  }
}
.radio .options .active.option + .option {
  @apply border-l-0;
}
</style>
