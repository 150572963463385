<template>
  <div>
    <div class="relative current-preview">
      <div class="mx-auto relative overflow-hidden">
        <div
          class="overflow-hidden mx-auto absolute origin-top-left"
          ref="viewport"
          :style="areaStyle"
        >
          <canvas
            v-if="deepARNeeded"
            ref="canvas"
            class="object-cover object-center min-w-full min-h-full w-full h-full"
          />
          <video
            v-if="!deepARNeeded"
            muted
            playsinline
            ref="video"
            @canplay="videoStarted"
            class="object-cover object-center min-w-full min-h-full w-full h-full"
            :style="{ transform: facingMode === 'environment' ? 'none' : 'scaleX(-1)' }"
          />
        </div>
        <img
          v-if="currentFrame?.image"
          class="z-10 relative"
          crossorigin="anonymous"
          :src="currentFrame.image.src"
          :style="{ width: `${stageConfig.width}px`, height: `${stageConfig.height}px` }"
        />
      </div>
      <div v-if="count > 0" class="counter">
        <span>{{ count }}</span>
      </div>
    </div>
    <div v-if="deepARNeeded" class="flex justify-center">
      <div class="gallery">
        <div
          class="item" v-for="(booth_prop, idx) in template.booth_props" :key="booth_prop.preview_url"
          :class="{ current: currentProps[booth_prop.slot] === idx }"
          @click="setEffect(idx)"
        >
          <img :src="booth_prop.preview_url" class="h-12">
        </div>
      </div>
    </div>
    <div class="controls">
      <button
        type="button"
        class="filled"
        :disabled="!canUpload || !ready"
        @click="fileInputEl.click()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 14" style="width: 1.25rem; height: 0.875rem">
          <path d="M16.13 5.03A6.25 6.25 0 0010 0a6.255 6.255 0 00-5.542 3.362A5.003 5.003 0 000 8.334c0 2.763 2.237 5 5 5h10.833c2.3 0 4.167-1.866 4.167-4.166 0-2.2-1.712-3.984-3.87-4.138zM11.666 7.5v3.333H8.333V7.5h-2.5L10 3.333 14.167 7.5h-2.5z"/>
        </svg>
      </button>
      <button
        type="button"
        @click="startShooting"
        :disabled="cameras.length === 0 || !ready || shooting"
        class="start"
      >
        <svg style="width: 45%; height: auto" xmlns="http://www.w3.org/2000/svg" fill="none" width="15" height="12" viewBox="0 0 15 12">
          <path d="M9.494 4.444a2.092 2.092 0 00-1.072.306.606.606 0 01.383.612.723.723 0 01-1.118.65.78.78 0 01-.26-.267c-.156.308-.234.65-.228.994a2.295 2.295 0 104.59 0 2.302 2.302 0 00-2.295-2.295z"/>
          <path d="M13.934 1.5H13.2L12.54.224c0-.15-.147-.225-.294-.225h-3.96c-.146 0-.293.075-.293.225l-.66 1.274H3.668v-.374A.35.35 0 003.301.75H1.835a.35.35 0 00-.367.375V1.5H.735a.7.7 0 00-.733.75v8.998a.7.7 0 00.733.75h13.199a.7.7 0 00.733-.75V2.25a.7.7 0 00-.733-.75zM2.202 4.498a.743.743 0 01-.734-.75.7.7 0 01.734-.75.75.75 0 010 1.5zm7.332 6a3.75 3.75 0 113.666-3.75 3.673 3.673 0 01-3.666 3.75z"/>
        </svg>
      </button>
      <button
        type="button"
        class="flip-camera filled"
        @click="flipCamera"
        :disabled="cameras.length < 2 || shooting"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 20" height="20" width="23">
          <path d="M16.74 3.375L15.878 0H6.622L5.76 3.375H3.119V1.663H1.8v1.712H0V19.09h22.5V3.375h-5.76zM4.522 9.907l1.01-.848.703.837a5.228 5.228 0 015.103-4.125 5.2 5.2 0 014.836 3.254l-1.221.496a3.887 3.887 0 00-3.615-2.432 3.907 3.907 0 00-3.818 3.099l1.062-.873.837 1.02-2.685 2.205-2.212-2.633zm11.733 4.558l-.363-.93a5.202 5.202 0 01-4.554 2.674 5.21 5.21 0 01-4-1.866l1.01-.847a3.895 3.895 0 002.99 1.394 3.888 3.888 0 003.494-2.175l-1.358.53-.48-1.227 3.228-1.26 1.261 3.227-1.228.48z"/>
        </svg>
      </button>
    </div>
    <transition
      name="flash"
      @afterEnter="takePhoto"
      @afterLeave="continueOrFinish"
      :duration="isBurst ? 0 : 100"
    >
      <div v-show="flash" class="flash"/>
    </transition>
    <canvas v-if="!deepARNeeded" class="hidden" ref="canvas"/>
    <video v-if="deepARNeeded" class="h-px w-px absolute opacity-0" @canplay="videoStarted" muted playsinline ref="video" />
    <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="uploadPhoto">
    <div
      class="hidden"
      v-for="frame in template.frames"
      :key="frame.id"
      :id="`stage_${frame.id}`"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { mapState } from 'pinia'
import { findFirst } from 'fp-ts/Array'
import { toNullable } from 'fp-ts/Option'
import { showAlert, fileToBase64 } from '~/utils/utils'
import { useStore } from '~/stores/store'
import { initDeepAR, nextEffect, DeepARInstance, clearEffect } from '../services/deepar'
import { generateFrames } from '../services/framer'

export default defineComponent({
  data() {
    return {
      flash: false,
      count: 0,
      stream: undefined as undefined | MediaStream,
      scale: 1,
      ready: false,
      cameras: [] as MediaDeviceInfo[],
      currentCameraIdx: 0,
      facingMode: 'user' as string | undefined,
      shooting: false,
      currentProps: reactive({} as { [key: string]: number }),
      deepAR: null as null | DeepARInstance,
      previousTime: 0,
      stageConfig: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    countdown (): number {
      if (this.currentPhotoIndex === 0) return this.template.countdown_before
      return this.template.countdown_between
    },
    frameUrl (): string | null {
      if (this.currentFrame) {
        return this.currentFrame.image_url.startsWith('http') ? this.currentFrame.image_url : `${document.location.origin}${this.currentFrame.image_url}`
      }
      return null
    },
    area (): Area {
      const area = this.template.areas[this.currentAreaIdx]
      if (area) {
        return area
      } else {
        return { left: 0, top: 0, width: this.stageConfig.width, height: this.stageConfig.height, angle: 0 }
      }
    },
    areaStyle (): Record<string, string> {
      return {
        width: `${this.scale * this.area.width}px`,
        height: `${this.scale * this.area.height}px`,
        top: `${this.scale * this.area.top}px`,
        left: `${this.scale * this.area.left}px`,
        transform: `rotate(${this.area.angle}deg)`
      }
    },
    currentPhotoIndex (): number {
      return this.photos.length % this.template.photos_count
    },
    currentFrame (): Frame | null {
      return toNullable(findFirst((frame: Frame) => (frame.photo_index === this.currentPhotoIndex))(this.template.frames))
    },
    currentAreaIdx (): number {
      return Math.min(Math.floor(this.photos.length / this.template.photos_count), this.template.areas.length - 1)
    },
    canUpload (): boolean {
      return this.template.photos_count === 1 && this.template.areas.length === 1
    },
    deepARNeeded (): boolean {
      return this.template.booth_props.length > 0 && !!this.event?.can_use_ar_props
    },
    isBurst (): boolean {
      return this.template.countdown_between === 0 && this.template.photos_count > 1
    },
    videoEl(): HTMLVideoElement {
      return this.$refs['video'] as HTMLVideoElement
    },
    canvasEl(): HTMLCanvasElement {
      return this.$refs['canvas'] as HTMLCanvasElement
    },
    fileInputEl(): HTMLInputElement {
      return this.$refs['fileInput'] as HTMLInputElement & { files: FileList }
    },
    ...mapState(useStore, {
      photos: 'photos',
      event: 'event',
      template: store => {
        if (!store.template) throw 'No template available'
        return store.template
      }
    })
  },
  async mounted () {
    const store = useStore()
    this.$wait.start('loading.images')
    await store.preloadImages()
    this.$wait.end('loading.images')

    this.resizeStage()
    if (!this.deepARNeeded) {
      this.ready = true
    }

    this.videoEl.addEventListener('canplay', () => {
      if (this.deepARNeeded) return;
      this.canvasEl.width = this.videoEl.videoWidth
      this.canvasEl.height = this.videoEl.videoHeight
    })

    try {
      const getAccessStream = await navigator.mediaDevices.getUserMedia({ video: {} })
      getAccessStream.getTracks().forEach((track) => { track.stop() })

      this.cameras = (await navigator.mediaDevices.enumerateDevices()).filter((d) => (d.kind === 'videoinput'))

      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' }
      })
      const tracks = this.stream.getVideoTracks()
      if (!tracks[0]) throw 'No video tracks present'
      const { deviceId } = tracks[0].getCapabilities()
      this.currentCameraIdx = this.cameras.findIndex((camera) => (camera.deviceId === deviceId))
      this.videoEl.srcObject = this.stream
      this.videoEl.play()
    } catch (error) {
      showAlert('We cannot access the camera of your device')
    }

    window.addEventListener('resize', this.resizeStage)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resizeStage)
    if (!this.stream) return
    this.stream.getTracks().forEach((track) => { track.stop() })
  },
  methods: {
    async videoStarted () {
      const store = useStore()
      if (this.deepARNeeded) {
        this.deepAR = await initDeepAR(
          this.canvasEl,
          this.videoEl,
          this.area.width,
          this.area.height,
          this.facingMode !== 'environment',
          (data: string) => {
            const store = useStore()
            store.photos.push(data)
          }
        )
        store.propsUsed = true
        this.ready = true
      } else {
        this.canvasEl.width = this.videoEl.videoWidth
        this.canvasEl.height = this.videoEl.videoHeight
        const context = this.canvasEl.getContext('2d')
        if (this.template.mirror_photo && context) {
          context.translate(this.videoEl.videoWidth, 0)
          context.scale(-1, 1)
        }
      }
    },
    async setEffect (idx: number) {
      const prop = this.template.booth_props[idx]
      if (!this.deepAR || !prop) return
      const slot = prop.slot
      if (this.currentProps[slot] === idx) {
        delete this.currentProps[slot]
        clearEffect(this.deepAR, slot)
      } else {
        this.currentProps[slot] = idx
        await nextEffect(this.deepAR, prop.slot, prop.deepar_url)
      }
    },
    async initCamera () {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => { track.stop() })
      }
      const currentCamera = this.cameras[this.currentCameraIdx]
      if (!currentCamera) throw 'Unknown camera selected'

      this.stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: {
            exact: currentCamera.deviceId
          }
        }
      })

      const track = this.stream.getVideoTracks()[0]
      if (!track) throw 'Stream does not have video tracks'
      const capabilities = track.getCapabilities()
      if (capabilities && capabilities.facingMode) {
        this.facingMode = capabilities.facingMode[0]
      }
      this.videoEl.srcObject = this.stream
      this.videoEl.play()
    },
    flipCamera () {
      this.currentCameraIdx = (this.currentCameraIdx + 1) % this.cameras.length
      this.initCamera()
    },
    async finish () {
      const store = useStore()
      this.$wait.start('processing.photo')
      const frames = await generateFrames(this.template, this.photos, this.event?.status)
      store.framesForUpload = frames
      this.$wait.end('processing.photo')
      this.$router.push({ name: 'background' })
    },
    async takePhoto () {
      await new Promise((resolve) => setTimeout(resolve, 100))
      const context = this.canvasEl.getContext('2d')
      if (context) {
        context.drawImage(this.videoEl, 0, 0, this.canvasEl.width, this.canvasEl.height)
      }
      if (!this.deepAR) {
        const store = useStore()
        const data = this.canvasEl.toDataURL()
        store.photos.push(data)
      } else {
        this.deepAR.takeScreenshot()
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
      if (this.template.countdown_between > 0 || this.template.photos_count === 1) {
        this.flash = false
      } else {
        this.previousTime = Date.now()
        this.continueOrFinish()
      }
    },
    async captureBurst () {
      const store = useStore()
      const now = Date.now()
      const timeoutDiff = now - this.previousTime
      const waitFor = 60 - timeoutDiff
      if (waitFor > 0) { await new Promise((resolve) => setTimeout(resolve, waitFor)) }
      this.previousTime = now
      const context = this.canvasEl.getContext('2d')
      if (context) {
        context.drawImage(this.videoEl, 0, 0, this.canvasEl.width, this.canvasEl.height)
      }
      if (!this.deepAR) {
        const data = this.canvasEl.toDataURL()
        store.photos.push(data)
      } else {
        this.deepAR.takeScreenshot()
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
      this.previousTime = Date.now()

      if (this.photos.length < this.template.areas.length * this.template.photos_count) {
        this.captureBurst()
      } else {
        this.$wait.end('capturing.photos')
        this.finish()
      }
    },
    async uploadPhoto () {
      const store = useStore()
      if (!this.fileInputEl.files || this.fileInputEl.files.length === 0) return
      const file = this.fileInputEl.files[0]
      if (!file) return
      const base64 = await fileToBase64(file)
      store.photos.push(base64)
      this.flash = false
      this.finish()
    },
    startShooting () {
      const context = this.canvasEl.getContext('2d')
      if (context && this.template.mirror_photo && this.facingMode === 'user') {
        context.translate(this.videoEl.videoWidth, 0)
        context.scale(-1, 1)
      }
      this.startCountdown()
    },
    continueOrFinish () {
      if (this.photos.length < this.template.areas.length * this.template.photos_count) {
        this.startCountdown()
      } else {
        this.finish()
      }
    },
    async startCountdown () {
      this.count = this.countdown
      this.shooting = true
      if (this.count === 0) {
        if (this.template.countdown_between > 0) {
          this.flash = true
        } else {
          this.takePhoto()
        }
      } else {
        const counter = setInterval(async () => {
          if (this.count < 0) return
          this.count -= 1
          if (this.count === 0) {
            clearInterval(counter)
            if (this.isBurst) {
              this.$wait.start('capturing.photos')
              this.captureBurst()
              return;
            } else {
              this.flash = true
            }
          }
        }, 1000)
      }
    },
    resizeStage () {
      if (!this.currentFrame || !this.currentFrame.image) return
      const widthScale = (window.innerWidth - 64) / this.currentFrame.image.naturalWidth
      const heightScale = (window.innerHeight * 0.45) / this.currentFrame.image.naturalHeight

      const scale = Math.min(widthScale, heightScale)

      this.stageConfig.width = this.currentFrame.image.naturalWidth * scale
      this.stageConfig.height = this.currentFrame.image.naturalHeight * scale

      this.scale = scale
    }
  }
})
</script>

<style lang="postcss" scoped>
.counter {
  font-size: 10rem;
  text-shadow: 2px 2px 3px theme('colors.gray.600');
  @apply absolute inset-0 z-40 text-white font-bold flex items-center justify-center;
}
.flash {
  @apply fixed inset-0 z-50 bg-white transition transition-opacity duration-100 ease-in;
  &.flash-enter-from {
    @apply opacity-0;
  }
  &.flash-leave-to {
    @apply opacity-0;
  }
}
#camera {
  .current-preview {
    @apply mx-auto;
    height: 50vh;
  }
}

input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.controls {
  @apply flex justify-center items-center mt-4 bg-transparent h-auto justify-center;
  > .grid > div {
    @apply flex justify-center items-center;
  }
  button {
    &[disabled] {
      @apply opacity-25;
    }
    line-height: 3rem;
    @apply text-white block mx-3 rounded-full flex h-12 items-center text-sm;
    .text {
      @apply hidden;
      padding: 0 1.25rem;
    }
    .circle {
      background-color: rgba(255, 255, 255, 0.1);
      @apply rounded-full h-10 w-10 mx-1 flex justify-center items-center;
    }
    &.start {
      @apply h-20 w-20 justify-center;
      .circle {
        @apply m-0;
        height: 3.5rem;
        width: 3.5rem;
      }
    }
  }
}
</style>