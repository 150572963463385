<template>
  <div
    v-if="survey"
    class="mx-auto w-full space-y-6 p-4"
  >
    <Answer
      v-for="question in survey.questions"
      :key="question.id"
      :question="question"
      :model-value="response.answers_attributes[question.id] || { question_id: question.id, value: question.kind === 'checkbox' ? false : '' }"
      @update:modelValue="response.answers_attributes[question.id] = $event"
    />
    <div class="flex justify-center mt-4">
      <NextBtn @click="save" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { create } from '../gateways/responses'
import { useStore } from '~/stores/store'
import Answer from '../components/survey/Answer.vue'
import { useRouter } from 'vue-router'
import { micrositeId } from '~/utils/utils'

const store = useStore()
const router = useRouter()
const response = reactive<SurveyResponse>({ answers_attributes: {} })

if (!store.needSurvey) {
  router.push({ name: 'mode' })
}

const survey = computed<Survey | null>(() => store.event?.booth_survey?.survey || null)

const save = async () => {
  const { data: { id } } = await create({ response: response })
  window.localStorage.setItem(`response_${micrositeId}`, `${id}`)
  store.responseId = id
  router.push({ name: 'mode' })
}
</script>