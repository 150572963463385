<template>
  <div>
    <div class="current-preview">
      <img
        class="object-contain max-h-full"
        ref="frame"
        v-if="currentFrame"
        :src="currentFrame"
      >
    </div>
    <div class="flex justify-center">
      <div class="gallery">
        <div
          v-if="!store.template?.force_bg_removal"
          class="item"
          :class="{ current: current === false }"
          @click="current = false"
        >
          <svg @click="current = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
            <path fill-rule="evenodd" d="M3 4.385C3 3.62 3.62 3 4.385 3h38.454L3 41.923V4.385zm1.163 40.597c.072.011.146.017.222.017h39.23c.764 0 1.384-.62 1.384-1.384V5.084L4.163 44.982zM4.385 0A4.385 4.385 0 000 4.385v39.23a4.385 4.385 0 004.385 4.384h39.23a4.385 4.385 0 004.384-4.384V4.385A4.385 4.385 0 0043.615 0H4.385z" clip-rule="evenodd"/>
          </svg>
        </div>
        <div
          v-for="(background, idx) in backgrounds"
          class="item"
          :key="background.id"
          :class="{ current: idx === current }"
          @click="current = idx"
        >
          <img :src="background.preview_url">
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <button
        @click="redo"
        type="button"
        class="px-5"
      >
        {{ $t('global.retake_button') }}
      </button>
      <NextBtn @click="store.framesForUpload = frames; router.push({ name: 'stickers' })" />
    </div>
    <div
      class="hidden"
      v-for="frame in store.template?.frames"
      :key="frame.id"
      :id="`stage_${frame.id}`"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, onBeforeUnmount, inject } from 'vue'
import { useStore } from '~/stores/store'
import removebg from '../services/removebg'
import { generateFrames } from '../services/framer'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { Wait } from '~/services/wait'

const store = useStore()
const router = useRouter()
const wait = inject('$wait') as Wait

const current = ref<number|false>(false)
const player = ref<null | number>(null)
const currentFrameIdx = ref<number>(0)
const goingUp = ref<boolean>(true)
const photosWithoutBg = ref<HTMLImageElement[] | string[]>([])
const preparedFrames = reactive<{[key: number]: string[]}>({})

const backgrounds = computed<BackgroundSet[]>(() => store.template?.background_sets || [])
const currentFrame = computed<string | undefined>(() => frames.value[currentFrameIdx.value])
const frames = computed<string[]>(() => {
  if (typeof current.value !== 'number') {
    return store.framesForUpload
  } else {
    return preparedFrames[current.value] || store.framesForUpload
  }
})

onMounted(() => {
  player.value = setInterval(
    () => step(),
    (store.template as Template).speed * 100
  )
  current.value = store.template?.force_bg_removal ? 0 : false
})

onBeforeUnmount(() => { if (typeof player.value === 'number') clearInterval(player.value) })

const step = () => {
  if (!store.template) return
  if (store.template.reverse) {
    if (goingUp.value && currentFrameIdx.value + 1 < store.template.frames.length) {
      currentFrameIdx.value += 1
    } else if (goingUp.value) {
      goingUp.value = false
    } else if (currentFrameIdx.value > 0) {
      currentFrameIdx.value -= 1
    } else {
      goingUp.value = true
    }
  } else {
    currentFrameIdx.value = (currentFrameIdx.value + 1) % store.template.frames.length
  }
}
const redo = () => {
  store.redo()
  router.push({ name: 'mode' })
}

watch(current, async (val) => {
  wait.start('processing.photos')
  if (typeof val === 'number' && photosWithoutBg.value.length === 0) {
    photosWithoutBg.value = await removebg(store.photos)
  }
  if (typeof val === 'number' && !preparedFrames[val] && store.template) {
    const frames = await generateFrames(
      store.template,
      photosWithoutBg.value,
      store.event?.status,
      backgrounds.value[val]
    )
    preparedFrames[val] = frames
  }
  wait.end('processing.photos')
})
</script>
