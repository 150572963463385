<template>
  <component :is="'style'" v-if="store.booth">
    @import url('{{store.booth?.style?.headingFont?.fontFamily?.url}}');
    @import url('{{store.booth?.style?.subheadingFont?.fontFamily?.url}}');
    @import url('{{store.booth?.style?.bodyFont?.fontFamily?.url}}');
  </component>
</template>

<script setup lang="ts">
import { useStore } from '~/stores/store'

const store = useStore()
</script>
