<template>
  <div class="px-8 flex flex-wrap justify-center">
    <div
      v-if="store.modes['photo'].length"
      class="w-2/5 mx-4 mb-8"
      @click="setMode('photo')"
    >
      <img class="m-auto" @contextmenu.prevent :src="photoButton" alt="">
    </div>
    <div
      v-if="store.modes['gif'].length"
      class="w-2/5 mx-4 mb-8"
      @click="setMode('gif')"
    >
      <img class="m-auto" @contextmenu.prevent :src="gifButton" alt="">
    </div>
    <div
      v-if="store.modes['burst'].length"
      class="w-2/5 mx-4 mb-8"
      @click="setMode('burst')"
    >
      <img class="m-auto" @contextmenu.prevent :src="burstButton" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '~/stores/store'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const photoButton = computed<string | undefined>(() => {
  if (!store.booth) return undefined
  
  if (store.booth.photo_button) {
    return URL.createObjectURL(store.booth.photo_button)
  } else if (!store.booth.delete_photo_button) {
    return store.booth.photo_button_url
  }
  return store.booth.photo_button_url
})
const gifButton = computed<string | undefined>(() => {
  if (!store.booth) return undefined
  if (store.booth.gif_button) {
    return URL.createObjectURL(store.booth.gif_button)
  } else if (!store.booth.delete_gif_button) {
    return store.booth.gif_button_url
  }
  return store.booth.gif_button_url
})
const burstButton = computed<string | undefined>(() => {
  if (!store.booth) return undefined
  if (store.booth.burst_button) {
    return URL.createObjectURL(store.booth.burst_button)
  } else if (!store.booth.delete_burst_button) {
    return store.booth.burst_button_url
  }
  return store.booth.burst_button_url
})

const setMode = (mode: BoothMode) => {
  store.mode = mode
  store.preselectTemplate()
  router.push({ name: 'template' })
}
</script>
