import { createRouter, createMemoryHistory } from 'vue-router'
import { useStore } from '~/stores/store'

import ModeSelector from '~/views/ModeSelector.vue'
import Survey from '~/views/Survey.vue'
import ErrorView from '~/views/Error.vue'
import Sharing from '~/views/Sharing.vue'
import Camera from '~/views/Camera.vue'
import TemplateSelector from '~/views/TemplateSelector.vue'
import BackgroundSelector from '~/views/BackgroundSelector.vue'
import Stickers from '~/views/Stickers.vue'
import Preview from '~/views/Preview.vue'
import ThankYou from '~/views/ThankYou.vue'
import Start from '~/views/Start.vue'

const routes = [
  { path: '/start', name: 'start', component: Start },
  {
    path: '/mode',
    name: 'mode',
    component: ModeSelector,
    beforeEnter: () => {
      const store = useStore()

      if (!store.mode) {
        return true
      } else {
        return { name: 'template' }
      }
    },
  },
  {
    path: '/survey',
    name: 'survey',
    component: Survey,
    beforeEnter: () => {
      const store = useStore()

      if (store.needSurvey) {
        return true
      } else {
        return { name: 'mode' }
      }
    },
  },
  { path: '/error', name: 'error', component: ErrorView },
  { path: '/sharing', name: 'sharing', component: Sharing },
  { path: '/camera', name: 'camera', component: Camera },
  {
    path: '/template',
    name: 'template',
    component: TemplateSelector,
    beforeEnter: () => {
      const store = useStore()

      if (!store.template) {
        return true
      } else {
        return { name: 'camera' }
      }
    },
  },
  {
    path: '/background',
    name: 'background',
    component: BackgroundSelector,
    beforeEnter: () => {
      const store = useStore()

      if (!store.canRemoveBg) {
        return { name: 'stickers' }
      }
      return true
    },
  },
  {
    path: '/stickers',
    name: 'stickers',
    component: Stickers,
    beforeEnter: () => {
      const store = useStore()
      
      if (store.permissions.booth_stickers && store.template?.stickers?.length) {
        return true
      } else {
        return { name: 'preview' }
      }
    },
  },
  { path: '/preview', name: 'preview', component: Preview },
  { path: '/thank_you', name: 'thank_you', component: ThankYou }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
});

router.beforeEach((_, from, next) => {
  const store = useStore()

  if(from.name && store.preview) {
    next(false)
  } else {
    next()
  }
})

export default router
