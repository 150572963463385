<template>
  <div>
    <div class="current-preview">
      <img
        v-if="currentTemplate"
        :src="currentTemplate.preview_url"
        class="object-contain max-h-full"
      >
    </div>
    <div class="flex justify-center">
      <div class="gallery">
        <div
          v-for="(template, idx) in store.templates"
          class="item"
          :key="template.id"
          :class="{ current: idx === current }"
          @click="current = idx"
        >
          <img :src="template.preview_url">
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <NextBtn @click="setTemplate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from '~/stores/store'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const current = ref(0)
const currentTemplate = computed<Template>(() => {
  const currentTemplate = store.templates[current.value]
  if (!currentTemplate) throw 'No template found'
  return currentTemplate
})

const setTemplate = () => {
  const store = useStore()
  store.templateId = current.value
  router.push({ name: 'camera' })
}
</script>
