<template>
  <div class="checkbox">
    <div class="icon square" v-if="!modelValue" @click="$emit('update:modelValue', true)" />
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="modelValue" @click="$emit('update:modelValue', false)" class="icon">
      <path fill="#fff" fill-rule="evenodd" d="M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm15.052 9.07a1.5 1.5 0 10-2.104-2.14L9.87 13.896l-2.819-2.774a1.5 1.5 0 10-2.104 2.139l3.87 3.81 1.053 1.035 1.052-1.036 8.13-8z" clip-rule="evenodd"/>
    </svg>
    <div @click="$emit('update:modelValue', !modelValue)" class="cursor-pointer input-label">
      {{label}}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean
    },
    label: {
      required: true,
      type: String
    }
  }
})
</script>

<style lang="postcss" scoped>
.checkbox {
  @apply flex items-center;
  .icon {
    min-height: 1.5rem;
    min-width: 1.5rem;
    @apply h-6 w-6 mr-2 cursor-pointer;
    &.square {
      border-color: rgba(255, 255, 255, 0.2);
      @apply opacity-25 bg-black rounded border;
    }
  }
}
</style>
