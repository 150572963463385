<template>
  <button
    class="reset absolute top-0 left-0 mt-4 mr-4 h-12 w-12 justify-center border md:left-20 lg:left-60 xl:left-80"
    type="button"
    @click="$emit('click')"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 10" width="7" height="10" style="margin-left: -4px;">
      <path stroke-linecap="round" stroke-width="2" d="M6 9L2 5l4-4"/>
    </svg>
  </button>
</template>
