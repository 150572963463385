import wait from './wait'

type DeepAROptions = {
  licenseKey: string;
  canvasWidth: number;
  canvasHeight: number;
  canvas: HTMLCanvasElement;
  numberOfFaces: 1;
  onInitialize: () => void;
  onScreenshotTaken?: (data: string) => void;
  onVideoStarted?: () => void;
  onFaceVisibilityChanged?: () => void;
  onImageVisibilityChanged?: () => void;
  onFaceTracked?: () => void;
  libPath?: string;
}

export type DeepARInstance = {
  startVideo(flipCamera: boolean): null;
  switchEffect(face: number, slot: string, path: string, callback?: (value: unknown) => void): null;
  clearEffect(slot: string): null;
  downloadFaceTrackingModel(model: string, callback?: (value: unknown) => void): null;
  takeScreenshot(): null;
  resume(): null;
  setVideoElement(video: HTMLVideoElement, mirror: boolean): null;
}

declare global {
  interface Window {
    DeepAR(options: DeepAROptions): DeepARInstance;
  }
}

export function initDeepAR (
  canvas: HTMLCanvasElement,
  video: HTMLVideoElement,
  canvasWidth: number,
  canvasHeight: number,
  mirror: boolean,
  onScreenshotTaken: (data: string) => void
): Promise<DeepARInstance> {
  canvas.width = canvasWidth
  canvas.height = canvasHeight
  return new Promise((resolve) => {
    const deepAR = window.DeepAR({
      licenseKey: import.meta.env.VITE_DEEPAR_API_KEY,
      canvasWidth,
      canvasHeight,
      canvas,
      numberOfFaces: 1,
      async onInitialize () {
        wait.start('loading.deepar')
        await new Promise((downloaded) => {
          deepAR.downloadFaceTrackingModel('https://picpix-assets.s3.amazonaws.com/deepar34/models-68-extreme.bin', downloaded)
        })
        wait.end('loading.deepar')
        deepAR.setVideoElement(video, mirror)
        deepAR.resume()
        resolve(deepAR)
      },
      onScreenshotTaken,
      libPath: 'https://picpix-assets.s3.amazonaws.com/deepar34/lib'
    })
  })

}

export async function nextEffect (deepAR: DeepARInstance, slot: string, effectUrl: string): Promise<void> {
  wait.start('loading.deepar.effect')
  await new Promise((resolve) => {
    deepAR.switchEffect(0, slot, effectUrl, resolve)
  })
  wait.end('loading.deepar.effect')
}

export function clearEffect (deepAR: DeepARInstance, slot: string): void {
  deepAR.clearEffect(slot)
}
