<template>
  <div>
    <div class="current-preview">
      <img
        class="object-contain max-h-full"
        ref="frameRef"
        :src="frame"
      >
      <div
        ref="stickerArea"
        class="absolute"
      />
    </div>
    <div class="flex justify-center">
      <div class="gallery">
        <div
          v-for="sticker in stickers"
          class="item"
          :key="sticker.id"
        >
          <img
            crossorigin="anonymous"
            :src="sticker.image_url"
            @click="addSticker(sticker.image_url)"
          >
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <button
        @click="redo"
        type="button"
        class="px-5"
      >
        {{ $t('global.retake_button') }}
      </button>
      <NextBtn @click="applyStickers" />
    </div>
    <div
      class="hidden"
      ref="renderStage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import Konva from 'konva'
import { useStore } from '~/stores/store'
import DeleteBtnImage from '../assets/delete-btn.svg'
import ResizeBtnImage from '../assets/resize-btn.svg'
import RotateBtnImage from '../assets/rotate-btn.svg'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const stickerArea = ref()
const frameRef = ref()
const renderStage = ref()

const player = ref<null | number>(null)
const currentFrameIdx = ref(0)
const goingUp = ref(true)
const stage = ref<null | Konva.Stage>(null)
const layer = ref<null | Konva.Layer>(null)
const tr = ref<null | Konva.Transformer>(null)

if (!store.permissions.booth_stickers && store.template?.stickers?.length) {
  router.push(({ name: 'preview' }))
}

const stickers = computed<Sticker[] | undefined>(() => store.template?.stickers)
const frame = computed<string>(() => {
  const frame = store.framesForUpload[currentFrameIdx.value] 
  if (!frame) throw 'No frame available'
  return frame
})
const stickerAreaEl = computed<HTMLDivElement>(() => stickerArea.value)
const frameEl = computed<HTMLImageElement>(() => frameRef.value)
const renderStageEl = computed<HTMLDivElement>(() => renderStage.value)

onMounted(() => {
  player.value = setInterval(() => step(),
  (store.template as Template).speed * 100)
})

onBeforeUnmount(() => {
  if (typeof player.value === 'number') clearInterval(player.value)
})

const destroyTransformer = () => {
  if (!tr.value) return
  tr.value.destroy()
  tr.value = null
  layer.value?.draw()
}
const step = () => {
  if (!store.template) return
  if (store.template.reverse) {
    if (goingUp.value && currentFrameIdx.value + 1 < store.template.frames.length) {
      currentFrameIdx.value += 1
    } else if (goingUp.value) {
      goingUp.value = false
    } else if (currentFrameIdx.value > 0) {
      currentFrameIdx.value -= 1
    } else {
      goingUp.value = true
    }
  } else {
    currentFrameIdx.value = (currentFrameIdx.value + 1) % store.template.frames.length
  }
}
const initStage = () => {
  stage.value = new Konva.Stage({
    container: stickerAreaEl.value,
    width: frameEl.value.width,
    height: frameEl.value.height
  })

  layer.value = new Konva.Layer()
  stage.value.add(layer.value)

  stage.value.on('mousedown touchstart', (e) => {
    if (e.target.attrs.image) return
    if (e.target._id === stage.value?._id && tr.value) {
      destroyTransformer()
    }
  })
}
const addSticker = (url: string) => {
  if (!stage.value) initStage()

  Konva.Image.fromURL(url, (stickerNode: Konva.Image) => {
    const scale = Math.min(stickerNode.getWidth() / frameEl.value.width / 4, frameEl.value.width / stickerNode.getWidth() / 4)
    stickerNode.setAttrs({
      x: frameEl.value.width / 10,
      y: frameEl.value.width / 10,
      scaleX: scale,
      scaleY: scale,
      draggable: true
    })
    stickerNode.on('mousedown touchstart', () => { initTransformer(stickerNode) })
    layer.value?.add(stickerNode)
    layer.value?.batchDraw()
  })
}
const initTransformer = (sticker: Konva.Image) => {
  if (!tr.value || tr.value._nodes[0] !== sticker) {
    destroyTransformer()
    tr.value = new Konva.Transformer({
      anchorStroke: 'transparent',
      anchorFill: 'transparent',
      anchorSize: 18,
      borderStroke: '#556074',
      borderDash: [3, 3],
      enabledAnchors: ['bottom-right'],
      rotateAnchorOffset: 0,
      nodes: [sticker]
    })
    Konva.Image.fromURL(DeleteBtnImage, (deleteImage: Konva.Image) => {
      tr.value?.add(deleteImage)
      deleteImage.on('click tap', () => {
        sticker.destroy()
        tr.value?.destroy()
        stage.value?.batchDraw()
      })
      deleteImage.on('mouseenter', () => {
        if (!stage.value) return
        stage.value.container().style.cursor = 'pointer'
      })

      deleteImage.on('mouseleave click tap', () => {
        if (!stage.value) return
        stage.value.container().style.cursor = 'default'
      })
      deleteImage.position({ x: -9, y: -9 })
      deleteImage.size({ width: 18, height: 18 })
      layer.value?.draw()
    })
    Konva.Image.fromURL(ResizeBtnImage, (resizeImage: Konva.Image) => {
      tr.value?.add(resizeImage)
      resizeImage.position({ x: tr.value?.getWidth() - 9, y: tr.value?.getHeight() - 9 })
      resizeImage.size({ width: 18, height: 18 })
      resizeImage.listening(false)
      layer.value?.draw()
      tr.value?.on('transform', () => {
        resizeImage.position({ x: tr.value?.getWidth() - 9, y: tr.value?.getHeight() - 9 })
      })
    })
    Konva.Image.fromURL(RotateBtnImage, (rotateImage: Konva.Image) => {
      tr.value?.add(rotateImage)
      rotateImage.position({ x: (tr.value?.getWidth() / 2) - 9, y: -9 })
      rotateImage.size({ width: 18, height: 18 })
      rotateImage.listening(false)
      tr.value?.on('transform', () => { rotateImage.x((tr.value?.getWidth() / 2) - 9) })
      layer.value?.draw()
    })
    layer.value?.add(tr.value as Konva.Transformer)
    layer.value?.draw()
  }
}
const applyStickers = async () => {
  const nodes = layer.value?.getChildren((elem) => (elem.getClassName() === 'Image'))
  
  if (!nodes || nodes?.length === 0) {
    router.push({ name: 'preview' })
    return
  }

  const refImageEl = document.createElement('img')
  if (store.framesForUpload[0]) {
    refImageEl.src = store.framesForUpload[0]
  }

  const stage = new Konva.Stage({
    container: renderStageEl.value,
    width: refImageEl.naturalWidth,
    height: refImageEl.naturalHeight
  })

  const stickerLayer = new Konva.Layer()
  const frameLayer = new Konva.Layer()
  stage.add(frameLayer)
  stage.add(stickerLayer)

  const scale = refImageEl.naturalWidth / frameEl.value.width

  nodes.forEach((node) => {
    const sticker = node.clone()
    sticker.draggable(false)
    sticker.x(node.getAbsolutePosition().x * scale)
    sticker.y(node.getAbsolutePosition().y * scale)
    sticker.scaleX(node.getAbsoluteScale().x * scale)
    sticker.scaleY(node.getAbsoluteScale().y * scale)
    stickerLayer.add(sticker)
  })
  stickerLayer.batchDraw()

  const result = [] as string[]

  for (const frame of store.framesForUpload) {
    await new Promise((resolve) => {
      Konva.Image.fromURL(frame, (frame: Konva.Image) => {
        frameLayer.add(frame)
        frameLayer.batchDraw()
        result.push(stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.7 }))
        frame.destroy()
        resolve(true)
      })
    })
  }

  store.framesForUpload = result
  router.push({ name: 'preview' })
}
const redo = () => {
  store.redo()
  router.push({ name: 'mode' })
}
</script>
