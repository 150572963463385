import api from './api'
import { micrositeId } from '~/utils/utils'
import { AxiosResponse } from 'axios'

export function create (
  photoId: number,
  delivery: PhotoDelivery
): Promise<AxiosResponse> {
  return api.post(`${micrositeId}/photos/${photoId}/photo_deliveries`, { photo_delivery: delivery })
}
