<template>
  <div id="booth-app" class="bg-cover">
    <BoothStyle />
    <div
      class="container mx-auto min-h-screen"
    >
      <div
        id="booth"
        class="min-h-screen flex flex-col"
      >
        <div class="flex-1 flex flex-col">
          <Heading />
          <router-view></router-view>
        </div>
        <Policies />
        <ResetBtn v-if="store.userInput || store.photoId" @click="store.reset(); $router.push({ name: 'mode' })" />
      </div>
    </div>
    <Disclaimer v-if="store.booth" :text="store.booth.disclaimer_text" :enabled="store.booth.disclaimer_enabled" />
    <Inactive v-if="store.event && eventInactive" :status="store.event.status" />
    <Loading />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, inject } from 'vue'
import { useStore } from '~/stores/store'
import ResetBtn from '~/components/buttons/Reset.vue'
import BoothStyle from '~/components/BoothStyle.vue'
import Policies from '~/components/Policies.vue'
import Disclaimer from '~/components/Disclaimer.vue'
import Inactive from '~/components/Inactive.vue'
import Loading from '~/components/Loading.vue'
import Heading from '~/components/Heading.vue'

const store = useStore()
const i18n: any = inject('i18n')

const eventInactive = computed<boolean>(() => !store.event || store.event.status === 'pending' || store.event.status === 'expired')
const bgImage = computed<string>(() => {
  if (store.booth?.background) {
    return `url('${URL.createObjectURL(store.booth.background)}')`
  } else if (store.booth?.background_url && !store.booth.delete_background) {
    return  `url('${store.booth?.background_url}')`
  }
  return 'none'
})

onMounted(() => {
  window.addEventListener(
    "message",
    (event) => {
      const trustedDomains = ['http://localhost:3000', 'https://alpha.picpicsocial.com', 'https://app.picpicsocial.com']
      if (!trustedDomains.some(domain => domain === event.origin)) return
    
      store.applyPreview(i18n, event.data)
    },
    false,
  );
})
</script>

<style lang="postcss">
  #booth-app {
    background-color: v-bind('store.booth?.bg_color');
    color: v-bind('store.booth?.style?.bodyFont?.color');
    background-image: v-bind(bgImage);
    .heading {
      h1 {
        font-family: v-bind('store.booth?.style?.headingFont?.fontFamily?.id || "inherit"');
        font-weight: v-bind('store.booth?.style?.headingFont?.fontWeight?.id || "inherit"');
        font-size: v-bind('store.booth?.style?.headingFont?.fontSize?.id || "1.75rem"');
        color: v-bind('store.booth?.style?.headingFont?.color');
      }
      h2 {
        font-family: v-bind('store.booth?.style?.subheadingFont?.fontFamily?.id || "inherit"');
        font-weight: v-bind('store.booth?.style?.subheadingFont?.fontWeight?.id || "inherit"');
        font-size: v-bind('store.booth?.style?.subheadingFont?.fontSize?.id || "1.25rem"');
        color: v-bind('store.booth?.style?.subheadingFont?.color');
      }
    }
    .input-label {
      font-family: v-bind('store.booth?.style?.bodyFont?.fontFamily?.id || "inherit"');
      font-weight: v-bind('store.booth?.style?.bodyFont?.fontWeight?.id || "inherit"');
      font-size: v-bind('store.booth?.style?.bodyFont?.fontSize?.id || "1rem"');
      color: v-bind('store.booth?.style?.bodyFont?.color');
    }
    button {
      background-color: v-bind('store.booth?.primary_button_bg_color');
      color: v-bind('store.booth?.primary_button_text_color');
      border-color: v-bind('store.booth?.primary_button_bg_color');
      &:after {
        background-color: v-bind('store.booth?.primary_button_bg_color');
      }
    }
    button.next {
      svg {
        path[stroke-width] {
          stroke: v-bind('store.booth?.primary_button_text_color');
        }
        path:not([stroke-width]) {
          fill: v-bind('store.booth?.primary_button_text_color');
        }
      }
    }
    button.reset {
      background-color: transparent;
      border-color: v-bind('store.booth?.border_color');
      svg path {
        stroke: v-bind('store.booth?.style?.bodyFont?.color');
      }
    }
    button.sharing-button {
      border-color: v-bind('store.booth?.border_color');
    }
    .gallery {
      .item.current {
        border-color: v-bind('store.booth?.border_color');
      }
      .item svg path {
        fill: v-bind('store.booth?.border_color');
      }
    }
    .loading {
      svg.spinner {
        fill: v-bind('store.booth?.primary_button_bg_color');
      }
    }
    dialog {
      background-color: v-bind('store.booth?.bg_color');
      font-family: v-bind('store.booth?.style?.bodyFont?.fontFamily?.id || "inherit"');
      font-weight: v-bind('store.booth?.style?.bodyFont?.fontWeight?.id || "inherit"');
      font-size: v-bind('store.booth?.style?.bodyFont?.fontSize?.id || "1rem"');
      color: v-bind('store.booth?.style?.bodyFont?.color');
      .border-b {
        border-color: v-bind('store.booth?.border_color');
      }
    }
    .mode-icon {
      svg {
        path:not([stroke-width]) {
          fill: v-bind('store.booth?.style?.bodyFont?.color');
        }
        path[stroke-width] {
          stroke: v-bind('store.booth?.style?.bodyFont?.color');
        }
      }
    }
    .controls button svg path {
      fill: v-bind('store.booth?.primary_button_text_color');
    }
    .gallery .item svg path {
      fill: v-bind('store.booth?.border_color');
    }
  }
</style>