import loadImage from 'image-promise'
import { defineStore } from 'pinia'
import { show } from '../gateways/events'
import { micrositeId, isPreview } from '~/utils/utils'
import { inject } from 'vue'
import { boothTranslations } from '~/translations'

function resolveMode ({ photos_count: photosCount, countdown_between: countdownBetween }: Template) {
  if (photosCount === 1) return 'photo'
  if (photosCount > 1 && countdownBetween === 0) return 'burst'
  return 'gif'
}

function hasOwnProperty<X extends Record<string, unknown>, Y extends PropertyKey>
(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

type Permissions = {
  booth_stickers: boolean;
  background_removal: boolean;
}

type TemplatesByMode = {
  [key in BoothMode]: Template[]
};

export const useStore = defineStore('main', {
  state: () => ({
    booth: null as Booth | null,
    event: null as OnlineEvent | null,
    modes: {
      photo: [],
      gif: [],
      burst: []
    } as TemplatesByMode,
    permissions: {
      booth_stickers: false,
      background_removal: false
    } as Permissions,

    mode: null as BoothMode | null,
    templateId: null as null | number,
    photoId: Number(window.localStorage.getItem(`photo_${micrositeId}`)),
    responseId: Number(window.localStorage.getItem(`response_${micrositeId}`)),
    bgRemovals: Number(window.localStorage.getItem(`bg_removals_${micrositeId}`)),
    userInput: false,
    propsUsed: false,

    photos: [] as string[],
    framesForUpload: [] as string[],

    preview: false as boolean
  }),
  getters: {
    templates (): Template[] {
      if (!this.mode) return []
      return this.modes[this.mode]
    },
    template (): Template | undefined {
      if (!this.mode || this.templateId === null) return undefined
      return this.modes[this.mode][this.templateId]
    },
    initialLocation (): { name: 'error' | 'sharing' | 'survey' | 'mode' | 'camera' | 'start' | string | undefined } {
      if (this.preview) { return { name: isPreview() } }
      if (!this.event) return { name: 'error' }
      if (this.photoId && this.event.booth_data_capture?.enabled) return { name: 'sharing' }
      if (this.booth && this.booth.start_screen) return { name: 'start' }
      return { name: 'survey' }
    },
    canRemoveBg (): boolean {
      if (!this.event) return false
      if (!this.event.can_remove_bg) return false
      if (this.mode && this.templateId !== null && this.modes[this.mode][this.templateId]?.background_sets?.length === 0) return false
      if (!this.permissions.background_removal) return false
      if (this.event.bg_removal_rate_limit === null) return true
      if (this.event.bg_removal_rate_limit > this.framesForUpload.length) return true
      return false
    },
    needSurvey(): boolean {
      return !!(this.event?.booth_survey?.enabled && !this.responseId)
    }
  },
  actions: {
    preselectDefaults() {
      this.userInput = false
      const modes = Object.keys(this.modes) as BoothMode[]
      const fullModes = modes.filter((k: BoothMode) => (this.modes[k].length > 0))
      this.mode = fullModes.length === 1 && fullModes[0] ? fullModes[0] : null
      if (this.mode) {
        this.templateId = this.modes[this.mode].length === 1 ? 0 : null
      }
    },
    async load () {
      try {
        const {
          data: {
            booth,
            photo_templates: templates,
            permissions,
            ...event
          }
        } = await show()
        permissions.forEach((permission: string) => {
          if (hasOwnProperty(this.permissions, permission)) {
            this.permissions[permission] = true
          }
        })
        this.event = { permissions, ...event }
        this.booth = booth
        templates.forEach((t) => {
          const mode = resolveMode(t)
          this.modes[mode].push(t)
        })
        this.preselectDefaults()
      } catch (e) {
        console.warn(e)
      }
    },
    async preloadImages () {
      if (!this.template) return
      await Promise.allSettled(this.template.frames.map(async (frame: Frame) => {
        if (frame.image) return
        frame.image = await loadImage(frame.image_url, { crossorigin: 'anonymous' })
      }))
    },
    reset () {
      this.preselectDefaults()
      window.localStorage.removeItem(`photo_${micrositeId}`)
      this.photoId = 0
      this.framesForUpload = []
      this.photos = []
    },
    redo() {
      this.framesForUpload = []
      this.photos = []
      this.photoId = 0
    },
    preselectTemplate () {
      if (this.mode) this.templateId = this.modes[this.mode].length === 1 ? 0 : null
    },
    applyPreview(i18n: any, data: any) {
      const { booth, photoTemplates } = data

      // Apply photo mode for preview page
      this.mode = 'photo'
      // Apply booth settings
      this.booth = booth
      // Apply photo templates
      this.modes = {
        photo: [],
        gif: [],
        burst: []
      }
      
      photoTemplates.forEach((t: any) => {
        const mode = resolveMode(t)
        this.modes[mode].push(t)
      })
      this.preselectDefaults()
      // Apply translations
      i18n?.global.setLocaleMessage('en', 
      {
        global: { ...boothTranslations.global, ...booth?.translations.en.global },
        social_sharing: { ...boothTranslations.social_sharing, ...booth?.translations.en.social_sharing },
        disclaimer: { ...boothTranslations.disclaimer, ...booth?.translations.en.disclaimer },
        start: { ...boothTranslations.start, ...booth?.translations.en.start },
        mode: { ...boothTranslations.mode, ...booth?.translations.en.mode },
        template_select: { ...boothTranslations.template_select, ...booth?.translations.en.template_select },
        mode_photo: { ...boothTranslations.mode_photo, ...booth?.translations.en.mode_photo },
        mode_gif: { ...boothTranslations.mode_gif, ...booth?.translations.en.mode_gif },
        mode_burst: { ...boothTranslations.mode_burst, ...booth?.translations.en.mode_burst },
        camera: { ...boothTranslations.camera, ...booth?.translations.en.camera },
        preview: { ...boothTranslations.preview, ...booth?.translations.en.preview },
        thank_you: { ...boothTranslations.thank_you, ...booth?.translations.en.thank_you },
        coming_soon: { ...boothTranslations.coming_soon, ...booth?.translations.en.coming_soon },
        booth_over: { ...boothTranslations.booth_over, ...booth?.translations.en.booth_over },
        bg_selector: { ...boothTranslations.bg_selector, ...booth?.translations.en.bg_selector },
        stickers: { ...boothTranslations.stickers, ...booth?.translations.en.stickers },
        data_capture: { ...boothTranslations.data_capture, ...booth?.translations.en.data_capture },
      })
    }
  }
})
