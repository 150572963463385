import { reactive, App } from 'vue'
import { some } from 'fp-ts/Array'

export class Wait {
  list = reactive({} as {[key: string]: number})

  is (name: string): boolean {
    if (name.endsWith('.*')) {
      const prefix = name.split('.')[0]
      if (prefix) {
        return some((waiter: string) => (waiter.startsWith(prefix)))(Object.keys(this.list))
      }
    }
    return !!this.list[name]
  }

  start (name: string): number | false {
    if (this.list[name]) console.warn(`Already waiting for ${this.list[name]}`)
    return !this.list[name] && (this.list[name] = Date.now())
  }

  end (name: string): number {
    const start = this.list[name]
    delete this.list[name]
    if (start) {
      return Date.now() - start
    } else {
      return 0
    }
  }

  any (): number {
    return Object.keys(this.list).length
  }

  clear (): {[key: string]: number} {
    const clearList = {} as {[key: string]: number}
    Object.keys(this.list).forEach((item) => {
      clearList[item] = this.end(item)
    })
    return clearList
  }
}

const wait = new Wait()

export default wait

export const $wait = {
  install: (app: App): void => {
    app.config.globalProperties['$wait'] = wait
    app.provide('$wait', wait)
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $wait: Wait;
  }
}
