<template>
  <div>
    <RadioInput
      v-if="question.kind === 'radio'"
      :options="question.question_options"
      :model-value="textValue"
      :label="question.description"
      @update:modelValue="input"
    />
    <StringInput
      v-if="question.kind === 'string'"
      :label="question.description"
      :model-value="textValue"
      @update:modelValue="input"
    />
    <TextInput
      v-if="question.kind === 'text'"
      :label="question.description"
      :model-value="textValue"
      @update:modelValue="input"
    />
    <CheckboxInput
      v-if="question.kind === 'checkbox'"
      :label="question.description"
      :model-value="booleanValue"
      @update:modelValue="input"
    />
    <MultiselectInput
      v-if="question.kind === 'multiselect'"
      :label="question.description"
      :model-value="textValue"
      :options="question.question_options"
      @update:modelValue="input"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import RadioInput from '../inputs/Radio.vue'
import StringInput from '../inputs/String.vue'
import TextInput from '../inputs/Text.vue'
import CheckboxInput from '../inputs/Checkbox.vue'
import MultiselectInput from '../inputs/Multiselect.vue'

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  question: {
    type: Object as PropType<SurveyQuestion>,
    required: true
  },
  modelValue: {
    type: Object as PropType<BooleanAnswer | TextAnswer>,
    required: true
  }
});

const textValue = computed<string>(() => props.modelValue ? (props.modelValue as TextAnswer).value : '')
const booleanValue = computed<boolean>(() => props.modelValue ? (props.modelValue as BooleanAnswer).value : false)

const input = (event: string | boolean | null): void => emit('update:modelValue', { question_id: props.question.id, value: event })
</script>
