<template>
  <label>
    <div class="mb-5 input-label">
      {{label}}
    </div>
    <textarea type="text" :model-value="modelValue" @input="input" />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    }
  },
  methods: {
    input(event: Event) {
      this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
    }
  }
})
</script>

<style scoped lang="postcss">
label {
  @apply block;
  textarea {
    @apply block mt-6 w-full py-3 px-5 rounded border;
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
}
</style>
