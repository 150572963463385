import { reduce } from 'fp-ts/Array'
import qs from 'qs'

export function showAlert (text: string, time = 3000): void {
  const wrapper = document.querySelector('.alert-wrapper')

  if (!wrapper) return

  const alert = document.createElement('div')
  alert.classList.add('alert')
  alert.setAttribute('data-controller', 'alert')
  alert.innerText = text
  wrapper.appendChild(alert)

  setTimeout(() => {
    alert.remove()
  }, time)
}

export function uuidv4 (): string {
  return ([1e7].concat(-1e3, -4e3, -8e3, -1e11)).join('').replace(/[018]/g, (c: string) => (
    // eslint-disable-next-line no-bitwise, no-mixed-operators, @typescript-eslint/no-non-null-assertion
    parseInt(c, 10) ^ crypto.getRandomValues(new Uint8Array(1))[0]! & 15 >> parseInt(c, 10) / 4).toString(16))
}

export const arrayToIndexObject = reduce(
  {}, (acc: Record<string, unknown>, item: unknown) => ({ ...acc, [Object.keys(acc).length]: item })
)

export const fileToBase64 = (file: File): Promise<string> => new Promise<string>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result as string)
  reader.onerror = error => reject(error)
})

export function getQueryId (): string {
  const msidQueryParam = qs.parse(document.location.search, { ignoreQueryPrefix: true })['msid']
  if (msidQueryParam) return String(msidQueryParam)
  const pathFirstFolder = document.location.pathname.match(/\/(.+)\//)
  if (pathFirstFolder) return String(pathFirstFolder[1])
  return document.location.pathname.slice(1)
}

export const dataURLtoFile = (dataurl: string): File => {
  const [prefix, data] = dataurl.split(',')
  if (!prefix || !data) throw 'Provided data is not dataURL'
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = prefix.match(/:(.*?);/)![1]!
  const bstr = atob(data)
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n > 0) {
    n -= 1
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], 'frame', { type: mime })
}

export function isPreview (): undefined | string {
  return qs.parse(document.location.search, { ignoreQueryPrefix: true })['preview']?.toString()
}

export const micrositeId = getQueryId()

export const modifySpecialSymbols = (translations: any) => {
  const translationsModified = {} as any

  for (const key in translations) {
    translationsModified[key] = {}

    for (const tr in translations[key]) {
      translationsModified[key][tr] = translations[key][tr]
        .replaceAll('@', `{'@'}`)
        .replaceAll('|', `{'|'}`)
    }
  }

  return translationsModified
}