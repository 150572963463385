import loadImage from 'image-promise'
import { removeBg } from '../gateways/photos'
import { micrositeId } from '~/utils/utils'
import { useStore } from '../stores/store'

export default function removebg (photosWithBg: string[]): Promise<HTMLImageElement[]> {
  const store = useStore()
  return Promise.all(
    photosWithBg.map(
      async (photo) => {
        const { data: { image } } = await removeBg(micrositeId, photo)
        window.localStorage.setItem(`bg_removals_${micrositeId}`, `${store.bgRemovals + 1}`)
        store.$patch({
          bgRemovals: store.bgRemovals + 1
        })
        return loadImage(image)
      }
    )
  )
}
