<template>
  <label class="mt-6 input-label">
    {{ label }}
    <div class="relative">
      <input type="tel" ref="input" v-model="phone" :required="required" pattern="[1-9]\d{1,14}" class="w-full">
      <div class="absolute top-0 bottom-0 ml-5 flex items-center">+</div>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    phone: {
      get (): string { return this.modelValue.slice(1) },
      set (value: string) { this.$emit('update:modelValue', `+${value}`) }
    }
  }

})
</script>

<style lang="postcss" scoped>
label {
  @apply block;
  input {
    @apply block mt-6 w-full py-3 pr-5 pl-8 rounded border relative;
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
}
</style>
