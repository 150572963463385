import api from './api'
import { AxiosResponse } from 'axios'
import { micrositeId } from '~/utils/utils'

interface OnlineEventResponse extends OnlineEvent {
  booth: Booth;
  photo_templates: Template[];
}

export function show (): Promise<AxiosResponse<OnlineEventResponse>> {
  return api.get(`${micrositeId}/event`)
}
