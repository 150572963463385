<template>
  <div v-if="store.booth">
    <TextBtns v-if="!isImageButton" />
    <ImageBtns v-if="isImageButton" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import TextBtns from '../components/modeSelector/TextBtns.vue'
import ImageBtns from '../components/modeSelector/ImageBtns.vue'
import { useStore } from '~/stores/store'

const store = useStore()

const isImageButton = computed<boolean>(() => {
  if (store.booth && !isNotAnyImageDeleted.value
      && store.booth.burst_button_url
      && store.booth.gif_button_url
      && store.booth.photo_button_url || isPreviewImages.value && !isNotAnyImageDeleted.value) { return true }
  return false
})

const isNotAnyImageDeleted = computed<boolean|undefined>(() => {
  return store.booth?.delete_burst_button || store.booth?.delete_gif_button || store.booth?.delete_photo_button
})
const isPreviewImages = computed(() => {
  return store.booth?.burst_button && store.booth?.gif_button && store.booth?.photo_button
})

</script>

<style lang="postcss">
.mode {
  @apply border rounded border-gray-200 cursor-pointer flex items-center w-full mx-auto p-px;
  &.active {
    @apply border-2 p-0;
  }
  .mode-icon {
    min-width: 5rem;
    min-height: 5rem;
    @apply h-20 w-20 flex items-center justify-center;
    svg {
      max-width: 80%;
      width: 80%;
      max-height: 40%;
      height: 40%;
    }
  }
  .vl {
    margin-right: 1.8rem;
    border-left-width: 1px;
    @apply self-stretch my-4 opacity-50;
  }
  .text {
    @apply py-4 pr-4;
    p {
      @apply text-sm opacity-50 mt-1 leading-4;
    }
    h3 {
      font-size: 1.375rem;
      line-height: 1.625rem;
    }
  }
}
</style>