<template>
  <div v-if="store.booth" class="policies flex justify-center mt-4">
    <a
      v-if="store.booth.tos_url"
      :href="store.booth.tos_url"
      :style="{ color: store.booth.primary_text_color }"
      target="_blanc"
    >
      {{ $t('global.terms_link_text') }}
    </a>
    <div class="vl self-stretch mx-6 opacity-50 border-l" />
    <a
      v-if="store.booth.policy_url"
      :href="store.booth.policy_url"
      :style="{ color: store.booth.primary_text_color }"
      target="_blanc"
      class="text-xs leading-6"
    >
      {{ $t('global.privacy_link_text') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '~/stores/store'

const store = useStore()
</script>
