<template>
  <div class="flex flex-col space-y-2">
    <h3
      class="text-center mt-4"
    >
      Microsite ID is not provided or not valid.
    </h3>
    <h4 class="text-lg font-thin text-center">
      Please use correct microsite ID with any of these options (by precedence):
    </h4>
    <div class="mx-auto">
      <div
        class="border p-4 mx-auto mb-2 text-center"
      >
        <p>
          add <span class="bg-gray-100 px-1 font-mono">msid</span> query param
        </p>
        <p class="text-sm">
          current URL should look like this:
          <br>
          <span class="text-gray-700 bg-gray-100 px-1 font-mono">{{ queryStringUrl }}</span>
        </p>
      </div>
      <div
        class="border p-4 mx-auto mb-2 text-center"
      >
        <p>
          add microsite ID as the first part of the URL
        </p>
        <p class="text-sm text-gray-700">
          current URL should look like this:
          <br>
          <span class="bg-gray-100 px-1 font-mono text-sm">
            {{ firstPathUrl }}
          </span>
        </p>
      </div>
      <div
        class="border p-4 mx-auto mb-2 text-center"
      >
        <p>
          use microsite ID as URL path
        </p>
        <p class="text-sm">
          current URL should look like this:
          <br>
          <span class="text-gray-700 bg-gray-100 px-1 font-mono">{{ completePathUrl }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import qs from 'qs'

const queryStringUrl = computed(() => {
  const url = new URL(document.location.href)
  const query = qs.parse(url.search, { ignoreQueryPrefix: true })
  query['msid'] = 'MICROSITE_ID'
  url.search = qs.stringify(query, { addQueryPrefix: false })
  return url.href
})
const firstPathUrl = computed(() => `${document.location.origin}/MICROSITE_ID/booth`)
const completePathUrl = computed(() => `${document.location.origin}/MICROSITE_ID`)
</script>
