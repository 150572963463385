<template>
  <dialog ref="disclaimer">
    <div class="text overflow-y-auto m-4">
      {{ $t('disclaimer.disclaimer_text') }}
    </div>
    <div class="border-b" />
    <form method="dialog" class="flex justify-center">
      <button class='px-4 m-4'>{{ $t('disclaimer.accept') }}</button>
    </form>
  </dialog>
</template>

<script setup lang="ts">
import { PropType, onMounted, ref, nextTick } from 'vue'
import dialogPolyfill from 'dialog-polyfill'

const props = defineProps({
  text: {
    required: true,
    type: String as PropType<string | null>
  },
  enabled: {
    required: true,
    type: Boolean
  }
});

const disclaimer = ref()

onMounted(() => {
  if (props.enabled) {
    dialogPolyfill.registerDialog(disclaimer.value as HTMLDialogElement)
    nextTick(() => {
      (disclaimer.value as HTMLDialogElement).showModal()
    })
  }
})
</script>
