<template>
  <button
    :type="type"
    class="next"
    :disabled="disabled"
    @click="open()"
  >
    <div class="text">
      {{ text }}
    </div>
    <div class="circle">
      <svg style="margin-left: 2px" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L1 9" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
  </button>
</template>

<script setup lang="ts">
import { PropType } from 'vue'

const props = defineProps({
  text: {
    default: '',
    type: String
  },
  disabled: {
    default: false,
    type: Boolean
  },
  icon: {
    default: null,
    type: String
  },
  type: {
    default: 'button',
    type: String as PropType<'button' | 'submit'>
  },
  link: {
    default: null,
    type: String
  }
});

const open = (): void => {
  if (props.link) {
    window.open(props.link)
  }
}

</script>
