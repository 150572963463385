import Konva from 'konva'
import loadImage from 'image-promise'

import pendingImage from '../assets/pending.png'
import draftImage from '../assets/draft.png'
import expiredImage from '../assets/expired.png'

function animationFrame () {
  return new Promise((resolve) => {
    window.requestAnimationFrame(resolve)
  })
}

function watermarkFileName (status: string) {
  if (status === 'pending') return pendingImage
  if (status === 'draft') return draftImage
  return expiredImage
}

async function addWatermark (layer: Konva.Layer, width: number, height: number, status: string) {
  let wmWidth
  let wmHeight

  const img = await loadImage(watermarkFileName(status))

  if (width >= height) {
    wmHeight = height / 6
    wmWidth = (img.naturalWidth / img.naturalHeight) * wmHeight
  } else {
    wmWidth = width / 2
    wmHeight = (img.naturalHeight / img.naturalWidth) * wmWidth
  }

  const watermark = new Konva.Image({
    x: width - wmWidth - 20,
    y: height - wmHeight - 20,
    width: wmWidth,
    height: wmHeight,
    image: img
  })

  layer.add(watermark)
}

function clipImage (image: HTMLImageElement, width: number, height: number) {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const aspectRatio = width / height

  let newWidth
  let newHeight

  const imageRatio = image.naturalWidth / image.naturalHeight

  if (aspectRatio >= imageRatio) {
    newWidth = width
    newHeight = width / imageRatio
  } else {
    newWidth = height * imageRatio
    newHeight = height
  }

  const x = ((newWidth - width) / 2 / newWidth) * image.naturalWidth
  const y = ((newHeight - height) / 2 / newHeight) * image.naturalHeight

  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.drawImage(
      image,
      x, y,
      image.naturalWidth - x,
      image.naturalHeight - y,
      0, 0,
      (newWidth * (image.naturalWidth - x)) / image.naturalWidth,
      (newHeight * (image.naturalHeight - y)) / image.naturalHeight
    )
  }

  const imgData = canvas.toDataURL()
  canvas.width = 0
  canvas.height = 0
  return loadImage(imgData)
}

async function replaceBackground (photo: (string | HTMLImageElement), background: string, width: number, height: number) {
  const bgLessPhoto = await loadImage(photo)
  const bgImage = await loadImage(background, { crossorigin: 'anonymous' })
  const clippedImg = await clipImage(bgLessPhoto, width, height)
  const clippedBackground = await clipImage(bgImage, width, height)

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.drawImage(clippedBackground, 0, 0)
    ctx.drawImage(clippedImg, 0, 0)
  }

  const imgData = canvas.toDataURL()
  canvas.width = 0
  canvas.height = 0
  return loadImage(imgData)
}

function resolveBackgroundImage (backgroundSet: BackgroundSet | null, frameIdx: number, photoIdx: number): null | Background {
  if (!backgroundSet) return null
  if (backgroundSet.kind === 'sync_to_frame') {
    const image = backgroundSet.images[frameIdx]
    if (!image) throw 'No image available at specified index'
    return image
  }
  if (backgroundSet.kind === 'sync_to_photo') {
    const image = backgroundSet.images[photoIdx]
    if (!image) throw 'No image available at specified index'
    return image
  }
  if (backgroundSet.images[0]) {
    return backgroundSet.images[0]
  }
  return null
}

export async function generateFrame ({
  id, image, image_width: width, image_height: height, photo_index: index
}: Frame,
  frameIdx: number,
  areas: Area[],
  photos: (string | HTMLImageElement)[],
  photosCount: number,
  backgroundSet: BackgroundSet | null,
  status: string
): Promise<string> {
  const stage = new Konva.Stage({
    width,
    height,
    container: `stage_${id}`
  })
  const layer = new Konva.Layer()
  stage.add(layer)

  const overlayImage = new Konva.Image({
    x: 0,
    y: 0,
    image,
    width,
    height
  })

  await Promise.all(areas.map(async (area, idx) => {
    let photo: HTMLImageElement
    const backgroundImage = resolveBackgroundImage(backgroundSet, frameIdx, index)
    if (backgroundImage) {
      const photoWithBg = photos[(photosCount * idx) + index]
      if (!photoWithBg) throw 'No photo taken for this area'
      photo = await replaceBackground(
        photoWithBg,
        backgroundImage.image_url,
        area.width,
        area.height
      )
    } else {
      const photoWithBg = photos[(photosCount * idx) + index]
      if (!photoWithBg) throw 'No photo taken for this area'
      const photoImg = await loadImage(photoWithBg)
      photo = await clipImage(photoImg, area.width, area.height)
    }

    const frame = new Konva.Image({
      x: area.left,
      y: area.top,
      image: photo,
      width: area.width,
      height: area.height,
      rotation: area.angle
    })
    layer.add(frame)
  }))

  layer.add(overlayImage)

  if (status !== 'running') await addWatermark(layer, width, height, status)

  layer.batchDraw()

  await animationFrame()

  const imageData = stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.7 })
  stage.setAttrs({
    height: 0,
    width: 0
  })
  stage.destroy()
  return imageData
}

export async function generateFrames (
  template: Template,
  photos: string[] | HTMLImageElement[],
  status: string | undefined,
  backgroundSet = null as BackgroundSet | null
): Promise<string[]> {
  const frames = []

  for (let index = 0; index < template.frames.length; index += 1) {
    // will not work in parallel in Safari
    // eslint-disable-next-line no-await-in-loop
    const currentFrame = template.frames[index]
    if (!currentFrame) throw 'No frame available'
    const frame = await generateFrame(
      currentFrame,
      index,
      template.areas,
      photos,
      template.photos_count,
      backgroundSet,
      status || 'draft'
    )
    frames.push(frame)
  }

  return frames
}
