import axios from 'axios'
import { stringify } from 'qs'

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_HOST}/api/v2/public/microsites/`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer (params) {
    return stringify(params)
  }
})

export default api
