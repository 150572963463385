import toFormData from 'json-form-data'
import wait from '../services/wait'
import api from './api'
import { micrositeId } from '~/utils/utils'
import { AxiosResponse } from 'axios'

type Photo = {
  id: number;
  microsite_photo_url: string;
}

export async function show (id: number): Promise<AxiosResponse<Photo>> {
  return api.get(`${micrositeId}/photos/${id}`)
}

export async function create (photo: PhotoParams): Promise<AxiosResponse<Photo>> {
  wait.start('uploading.photo')
  let result
  try {
    result = await api.post(
      `${micrositeId}/photos`,
      toFormData({ photo }, { showLeafArrayIndexes: false }),
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 300000,
        onUploadProgress ({ loaded, total }) {
          if (loaded === total) {
            wait.end('uploading.photo')
            wait.start('processing.photo')
          }
        }
      }
    )
  } finally {
    wait.end('processing.photo')
  }
  return result
}

export function removeBg (id: string, image: string): Promise<AxiosResponse> {
  return api.post(`${id}/photos/remove_bg`, { image })
}
