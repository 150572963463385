<template>
  <dialog
    ref="dialog"
    class="bg-white text-gray p-8 rounded"
  >
    <div
      class="text overflow-y-auto m-4 text-center max-w-[12.5rem]"
      v-if="status === 'expired'"
    >
      <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80" height=80 width=80>
          <g clip-path="url(#clip0)">
            <path fill="#556074" d="M78.849 36.76a56.198 56.198 0 01-12.999-8.94 173.41 173.41 0 004.446-23.062 2.252 2.252 0 00-1.143-2.615 2.061 2.061 0 00-2.476.814c-6.879 8.228-16.315 7.283-26.3 6.287-9.062-.906-18.379-1.81-25.788 4.417a7.329 7.329 0 10-9.759 7.89l14.952 53.53a3.846 3.846 0 004.744 2.668l1.206-.346a3.828 3.828 0 002.66-4.722l-4.738-16.967c6.92-8.864 16.563-7.924 26.772-6.904 10.238 1.02 20.827 2.08 28.612-7.23l.004-.005a4.292 4.292 0 00.877-3.444 1.923 1.923 0 00-1.07-1.372zM2.666 14.666a4.667 4.667 0 019.334 0 4.617 4.617 0 01-.69 2.408c-.17.216-.347.406-.513.633-.02.039-.037.079-.052.12a4.652 4.652 0 01-8.079-3.161zm23.052 59.619c-.154.272-.41.47-.712.552l-1.206.345a1.183 1.183 0 01-1.45-.822L7.72 21.981a7.275 7.275 0 003.44-1.07l9.74 34.86.005.01 4.918 17.618c.084.298.047.617-.105.887zM76.99 39.872c-6.878 8.224-16.31 7.282-26.299 6.284-9.947-.994-20.211-2.019-27.91 6.427-2.51-8.96-5.013-17.922-7.512-26.886l-1.913-6.857c.079-.113.15-.232.223-.35 6.903-8.538 16.444-7.599 26.533-6.592 9.651.963 19.615 1.96 27.318-5.806a168.473 168.473 0 01-4.17 21.091 2.516 2.516 0 00.757 2.578 60.47 60.47 0 0013.31 9.234c-.033.316-.15.619-.337.877z"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#556074" d="M0 0h80v80H0z"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <h1 class="text-xl mt-8 mb-4">{{ $t('booth_over.heading') }}</h1>
     {{ $t('booth_over.subheading') }}
    </div>
    <div
      class="text overflow-y-auto m-4 text-center max-w-[12.5rem]"
      v-if="status === 'pending'"
    >
      <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80"  height=80 width=80>
          <g fill="#556074" clip-path="url(#clip0)">
            <path d="M61.333 0H18.667c-2.206 0-4 1.795-4 4s1.794 4 4 4h42.666c2.206 0 4-1.795 4-4s-1.795-4-4-4zm0 5.333H18.667A1.335 1.335 0 0117.332 4c0-.736.599-1.333 1.334-1.333h42.666c.735 0 1.334.597 1.334 1.333s-.6 1.333-1.334 1.333zM61.333 72H18.667c-2.206 0-4 1.795-4 4s1.794 4 4 4h42.666c2.206 0 4-1.795 4-4s-1.795-4-4-4zm0 5.333H18.667A1.335 1.335 0 0117.332 76c0-.736.599-1.333 1.334-1.333h42.666c.735 0 1.334.597 1.334 1.333s-.6 1.333-1.334 1.333z"/>
            <path d="M49.501 35.051C56.272 28.281 60 19.28 60 9.706V6.667c0-.737-.597-1.333-1.333-1.333H21.333c-.736 0-1.333.596-1.333 1.333v3.039c0 9.575 3.728 18.575 10.497 25.345l1.34 1.34a5.139 5.139 0 011.496 3.61 5.143 5.143 0 01-1.494 3.609l-1.34 1.34C23.728 51.72 20 60.72 20 70.295v3.039c0 .737.597 1.333 1.333 1.333h37.334c.736 0 1.333-.596 1.333-1.333v-3.039c0-9.573-3.728-18.574-10.499-25.345l-1.34-1.34A5.07 5.07 0 0146.667 40a5.07 5.07 0 011.494-3.609l1.34-1.34zm-3.225-.545A7.714 7.714 0 0044 40c0 2.077.808 4.028 2.276 5.494l1.34 1.34c6.267 6.267 9.717 14.599 9.717 23.46v1.706H22.667v-1.706c0-8.862 3.45-17.193 9.716-23.46l1.34-1.34A7.712 7.712 0 0036 40.001a7.717 7.717 0 00-2.276-5.495l-1.34-1.34c-6.267-6.267-9.717-14.599-9.717-23.46V8h34.666v1.705c0 8.861-3.45 17.193-9.717 23.46l-1.34 1.34z"/>
            <path d="M51.268 22.463c-.135-.339-.593-1.13-1.935-1.13-6.928 0-10.014-1.484-11.239-2.37a5.699 5.699 0 00-5.244-.771c-1.678.593-2.908 1.913-3.37 3.62a4.35 4.35 0 00-.142.732c-.02.263.038.547.167.777 1.25 2.215 2.69 4.55 4.765 6.626l1.339 1.34a10.36 10.36 0 013.057 7.38 1.333 1.333 0 102.667 0c0-2.788 1.085-5.41 3.056-7.38l1.341-1.342c.33-.328.759-.733 1.23-1.176 3.448-3.241 4.897-4.818 4.308-6.306zm-6.136 4.364c-.493.465-.944.89-1.288 1.233l-1.341 1.341a13.098 13.098 0 00-2.505 3.491 13.095 13.095 0 00-2.503-3.49l-1.34-1.34c-1.637-1.64-2.835-3.443-4.082-5.618.33-1.113 1.183-1.567 1.667-1.739a3.033 3.033 0 012.79.42c1.699 1.229 5.048 2.678 11.465 2.856-.825.928-2.118 2.146-2.863 2.846zM54.574 67.896c-.334-4.323-2.067-6.117-4.073-8.197-.27-.282-.553-.574-.843-.883A8.922 8.922 0 0043.166 56h-6.334a8.925 8.925 0 00-6.492 2.816c-.29.31-.57.6-.842.881-2.006 2.08-3.74 3.876-4.073 8.2a1.334 1.334 0 001.33 1.436h26.49a1.335 1.335 0 001.33-1.437zm-26.301-1.23c.452-2.327 1.53-3.442 3.145-5.117.28-.29.57-.593.87-.912a6.242 6.242 0 014.544-1.97h6.334a6.25 6.25 0 014.546 1.97c.3.322.59.622.872.914 1.614 1.674 2.69 2.79 3.144 5.116H28.273zM39.96 42.666c-.736 0-1.334.596-1.334 1.333v1.334a1.333 1.333 0 102.667 0v-1.334c0-.737-.597-1.333-1.333-1.333zM39.96 49.334c-.736 0-1.334.596-1.334 1.333v1.334a1.333 1.333 0 102.667 0v-1.334c0-.737-.597-1.333-1.333-1.333z"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#556074" d="M0 0h80v80H0z"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <h1 class="text-xl mt-8 mb-4"> {{ $t('coming_soon.heading') }} </h1>
      {{ $t('coming_soon.subheading') }}
    </div>
  </dialog>
</template>

<script setup lang="ts">
import { PropType, onMounted, nextTick, ref } from 'vue'
import dialogPolyfill from 'dialog-polyfill'

type HTMLDialogElementExt = HTMLDialogElement & {
  showModal(): void;
}

defineProps({
  status: {
    type: String as PropType<'draft' | 'expired' | 'pending' | 'running'>,
    required: true
  }
});

const dialog = ref()

onMounted(() => {
  dialogPolyfill.registerDialog(dialog.value as HTMLDialogElementExt)
  nextTick(() => {
    (dialog.value as HTMLDialogElementExt).showModal()
  })
})
</script>
