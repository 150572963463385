<template>
  <div class="mt-8 heading">
    <h1 v-if="startHeading">{{ startHeading }}</h1>
    <h2 v-if="startSubheading">{{ startSubheading }}</h2>
    <div class="mx-auto w-full md:w-1/2 lg:w-1/3 xl:w-1/4 space-y-6">
      <div class="flex justify-center mt-4 flex-col space-y-4">
        <NextBtn @click="$router.push(nextRoute)" :text="startButtonText" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '~/stores/store'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore()

const nextRoute = computed(() => {
  if(store.needSurvey){
    return { name: 'survey' }
  } else {
    return { name: 'mode' }
  }
})
const startHeading = computed<string | undefined>(() => t('start.heading') || '')
const startSubheading = computed<string | undefined>(() => t('start.subheading') || '')
const startButtonText = computed<string | undefined>(() => t('start.button_text') || '')
</script>
